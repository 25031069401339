<template>
  <Page
    v-if="
      $can('create', 'catalog') ||
        $can('update', 'catalog') ||
        $can('read', 'catalog')
    "
    :loading="loading"
    :subtitle="subtitle"
    has-actions
  >
    <b-switch
      v-if="action == 'edit'"
      slot="actions"
      :disabled="
        (pack.active && !this.$can('delete', 'catalog')) ||
          (!pack.active && !this.$can('update', 'catalog'))
      "
      :value="pack.active"
      type="is-success"
      @input="activateOrDesactivatePack"
    >
      {{ $tc("active", 1) }}
    </b-switch>
    <Card content-class="px-0 py-1">
      <div
        v-if="loading"
        style="min-height: 200px"
      >
        <b-loading
          v-model="loading"
          :is-full-page="false"
        />
      </div>
      <b-tabs
        v-else
        v-model="activeTab"
        expanded
      >
        <b-tab-item
          :label="$t('general')"
          value="general"
        >
          <ValidationObserver
            ref="observer"
            tag="div"
          >
            <form
              method="post"
              autocomplete="off"
              class="columns is-multiline is-mobile mt-2"
              @submit.prevent="save"
            >
              <!-- <div class="column is-4-tablet is-12-mobile">
                <b-autocomplete-validation
                  v-model="pack.brand_id"
                  :expanded="true"
                  :label="$tc('brand', 1) + ' (' + $t('optional') + ')'"
                  :data="brands"
                  :placeholder="$t('choose')"
                  vid="brand"
                  :disabled="disableInfo"
                  item-text="label"
                  field="id"
                />
              </div> -->

              <div class="column is-6-tablet is-12-mobile">
                <b-autocomplete-validation
                  v-model="pack.category_id"
                  :expanded="true"
                  :label="$tc('category', 1)"
                  :data="categories"
                  :placeholder="$t('choose')"
                  vid="category_id"
                  :disabled="disableInfo"
                  item-text="label"
                  field="id"
                />
              </div>

              <div class="column is-6-tablet is-12-mobile">
                <b-switch
                  v-model="pack.noindex"
                  class="mt-2"
                >
                  {{ $t("noindex") }}
                </b-switch>
              </div>

              <template v-for="(category, c_index) in pack.categories">
                <div
                  :key="`title_${c_index}`"
                  class="column is-12 pt-0"
                >
                  <b>{{ `${$t("category_product")} ${c_index + 1}` }}</b>
                </div>
                <b-autocomplete-validation
                  :key="`category_${c_index}`"
                  v-model="category.id"
                  class="column is-6-tablet is-12-mobile py-0"
                  :expanded="true"
                  :label="`${$tc('category', 1)}`"
                  :data="categories3rdLevel"
                  :placeholder="$t('choose')"
                  :vid="`category_${c_index}`"
                  :disabled="disableInfo"
                  item-text="label"
                  field="id"
                  @change="setCategories"
                />

                <div
                  :key="`brand_${c_index}_${category.selectProduct}`"
                  class="column is-4-tablet is-12-mobile is-flex py-0"
                >
                  <SearchProduct
                    v-if="category.selectProduct"
                    :label="$tc('product', 1) + ' (' + $t('optional') + ')'"
                    :disabled="disableInfo"
                    :selected-prod="`${
                      category.product ? category.product.label : ''
                    }`"
                    :sites-ids="sitesIdArr"
                    :category-id="category.id"
                    :warehouse-id="1"
                    type="product"
                    class="is-flex-grow-1"
                    hide-details
                    pack
                    @selectItem="(p) => (category.product = p)"
                  />

                  <b-autocomplete-validation
                    v-else
                    :key="`brand_${c_index}`"
                    v-model="category.brand_id"
                    class="is-flex-grow-1"
                    :expanded="true"
                    :label="$tc('brand', 1) + ' (' + $t('optional') + ')'"
                    :data="brands"
                    :placeholder="$t('choose')"
                    vid="brand"
                    :disabled="disableInfo"
                    item-text="label"
                    field="id"
                  />

                  <span
                    class="is-clickable ml-2 mt-2"
                    @click="switchBrandProduct(c_index)"
                  >
                    <b-icon
                      icon="swap-horizontal"
                      type="is-grey"
                      custom-size="mdi-18px"
                    />
                  </span>
                  <!-- selectProduct -->
                </div>

                <b-input-validation
                  :key="`quantity_${c_index}`"
                  v-model="category.quantity"
                  class="column is-2-tablet is-12-mobile py-0"
                  :rules="category.id ? 'required' : ''"
                  vid="quantity"
                  :placeholder="placeholders.number['1']"
                  :disabled="disableInfo"
                  input-type="number"
                  :label="$t('quantity')"
                />
              </template>

              <div
                v-if="
                  $can('create', 'catalog.seo') ||
                    $can('update', 'catalog.seo') ||
                    $can('read', 'catalog')
                "
                class="column is-12"
              >
                <b-tabs
                  v-model="selectedSite"
                  type="is-boxed"
                  class="mb-0 paddingless"
                >
                  <b-tab-item
                    v-for="(site, i) in pack.sites"
                    :key="'tab' + site.id + '-' + i"
                    :value="`${site.id}`"
                  >
                    <template slot="header">
                      <img
                        :src="'/img/sites/' + site.id + '.svg'"
                        width="20"
                        height="20"
                        alt
                        class="mr-2"
                      >
                      {{ site.label }}
                    </template>

                    <div class="column is-3-tablet is-12-mobile">
                      <b-input-validation
                        v-model="site.discount"
                        :vid="'discount' + site.id"
                        :placeholder="placeholders.number[2]"
                        :disabled="disableInfo"
                        suffix="%"
                        input-type="number"
                        :label="$t('discount')"
                      />
                    </div>

                    <b-tabs
                      :class="
                        $device.mobile ? 'paddingless' : 'paddingless-right'
                      "
                      :vertical="!$device.mobile"
                    >
                      <b-tab-item
                        v-for="(lang, j) in site.languages"
                        :key="'lang-' + lang.iso + '-' + j"
                        header-class="mb-4"
                      >
                        <template slot="header">
                          <img
                            :src="
                              '/img/langs/' + lang.iso + '.svg'
                            "
                            width="18"
                            height="18"
                            class="mr-2"
                          >
                          {{ lang.label }}
                        </template>
                        <div
                          style="
                            max-width: fit-content;
                            min-width: -webkit-fill-available;
                          "
                        >
                          <div
                            class="columns is-multiline is-mobile ml-2"
                            :class="!$device.mobile ? 'ml-2' : ''"
                          >
                            <div class="column is-6-tablet is-12-mobile">
                              <b-input-validation
                                v-model="lang['name']"
                                :vid="'name' + site.id + lang.iso"
                                :rules="
                                  site.id == 1 && lang.iso == 'fr'
                                    ? 'required'
                                    : ''
                                "
                                :disabled="disableInfo"
                                :label="$tc('name', 1)"
                                maxlength="60"
                                :placeholder="placeholders.pack.name"
                                @blur="
                                  validSeoName(
                                    $event,
                                    i,
                                    site.id,
                                    j,
                                    lang.iso,
                                    lang.disabled_permalink
                                  )
                                "
                              />
                            </div>

                            <div class="column is-6-tablet is-12-mobile">
                              <b-input-validation
                                v-model="lang.permalink"
                                :rules="
                                  getPermalinkValidation(
                                    site.id,
                                    lang.iso,
                                    lang.disabled_permalink
                                  )
                                "
                                :vid="'permalink' + site.id + lang.iso"
                                validation-mode="lazy"
                                :placeholder="placeholders.pack.permalink"
                                :disabled="
                                  disableInfo || lang.disabled_permalink
                                "
                                :label="$t('permalink')"
                                maxlength="60"
                              />
                            </div>

                            <div class="column is-6-tablet is-12-mobile">
                              <b-input-validation
                                v-model="lang['meta_title']"
                                :disabled="disableInfo"
                                :placeholder="placeholders.pack.meta_title"
                                :vid="'meta_title' + site.id + lang.iso"
                                :label="
                                  $t('meta_title') + ' (' + $t('optional') + ')'
                                "
                                maxlength="90"
                                counter="90"
                              />
                            </div>

                            <div class="column is-6-tablet is-12-mobile">
                              <sortableTagInput
                                v-model="lang['keypoints']"
                                :label="
                                  $tc('keypoint', 2) +
                                    ' (' +
                                    $t('optional') +
                                    ')'
                                "
                                ref-value="keypoints_tagInput"
                                :placeholder="
                                  lang['keypoints'] &&
                                    lang['keypoints'].length == 0
                                    ? placeholders.product.keypoints
                                    : ''
                                "
                                :data="[]"
                                :disabled="disableInfo"
                                editable
                                allow-new
                                field="label"
                                sortable
                              />
                            </div>

                            <div class="column is-6-tablet is-12-mobile">
                              <b-input-validation
                                v-model="lang['meta_description']"
                                input-type="textarea"
                                :placeholder="
                                  placeholders.pack.meta_description
                                "
                                :vid="'meta_description' + site.id + lang.iso"
                                :label="
                                  $t('meta_description') +
                                    ' (' +
                                    $t('optional') +
                                    ')'
                                "
                                rows="2"
                                auto-grow
                                :disabled="disableInfo"
                                maxlength="300"
                                counter="300"
                                :suffix="true"
                              >
                                <b-dropdown
                                  slot="suffix"
                                  position="is-bottom-left"
                                  aria-role="list"
                                >
                                  <template #trigger="{ active }">
                                    <b-button
                                      :label="$tc('variable', 2)"
                                      :icon-right="
                                        active ? 'chevron-up' : 'chevron-down'
                                      "
                                    />
                                  </template>

                                  <b-dropdown-item
                                    v-for="v in copyVariables.list"
                                    :key="v.label"
                                    aria-role="listitem"
                                    @click="$root.copytoClipboard(v.value)"
                                  >
                                    {{ v.label }}
                                  </b-dropdown-item>
                                </b-dropdown>
                              </b-input-validation>
                            </div>

                            <div class="column is-6-tablet is-12-mobile">
                              <b-input-validation
                                v-model="lang['directions']"
                                input-type="textarea"
                                :placeholder="placeholders.pack.directions"
                                :vid="'directions' + site.id + lang.iso"
                                :disabled="disableInfo"
                                :label="
                                  $t('directions') + ' (' + $t('optional') + ')'
                                "
                                rows="2"
                                auto-grow
                              />
                            </div>

                            <div class="column is-12">
                              <b-input-validation
                                v-model="lang['description']"
                                input-type="textarea"
                                :placeholder="placeholders.pack.description"
                                :vid="'description' + site.id + lang.iso"
                                :disabled="disableInfo"
                                :label="
                                  $t('description') +
                                    ' (' +
                                    $t('optional') +
                                    ')'
                                "
                                rows="6"
                                auto-grow
                              />
                            </div>
                          </div>
                        </div>
                      </b-tab-item>
                    </b-tabs>
                  </b-tab-item>
                </b-tabs>
              </div>
              <div class="column pt-0">
                <b-button
                  type="is-primary"
                  :disabled="save_loading || disableInfo"
                  :loading="save_loading"
                  tag="input"
                  native-type="submit"
                  :value="$t('save')"
                >
                  {{ $t("save") }}
                </b-button>
              </div>
            </form>
          </ValidationObserver>
        </b-tab-item>

        <b-tab-item
          :label="$t('images')"
          :disabled="!id"
          value="images"
        >
          <Images
            v-if="id"
            :id="id"
            :images="pack.images"
            :action="action"
          />
        </b-tab-item>
      </b-tabs>
    </Card>
  </Page>
</template>

<script>
import SearchProduct from "@/components/forms/elements/searchProduct.vue";
import sortableTagInput from "@/components/forms/elements/sortableTagInput";
import Images from "@/components/forms/pack/Images";

export default {
  components: {
    SearchProduct,
    sortableTagInput,
    Images,
  },
  data() {
    return {
      loading: true,
      disableInfo: false,
      save_loading: false,
      activeTab: "general",
      selectedSite: 0,
      action: "create",
      notFound: false,
      pack: {},
      categories: [],
      categories3rdLevel: [],
      brands: [],
      id: null,
      subtitle: " ",
      sitesIdArr : []
    };
  },
  created() {
    this.getPack();
    this.updateTitle(this.$tc("create_pack", 1));
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.action = "edit";
      this.updateTitle(`${this.$t("edit")} ${this.$tc("pack", 1)}`);
    }
    if (!this.$can("update", "catalog") && this.action == "edit")
      this.disableInfo = true;
    if (!this.$can("create", "catalog") && this.action == "create")
      this.disableInfo = true;
  },
  methods: {
    switchBrandProduct(index) {
      let cat = { ...this.pack.categories[index] };
      let result;

      if (cat.hasOwnProperty("selectProduct")) {
        result = !cat.selectProduct;
      } else {
        result = true;
      }
      cat.selectProduct = result;

      this.pack.categories.splice(index, 1, cat);
    },
    getPermalinkValidation(site_id, lang_iso, disabled_permalink) {
      let required = site_id == 1 && lang_iso == "fr" ? "|required" : "";
      let valid_permalink = `valid_pack_permalink:${site_id}/${lang_iso}/check`;
      return this.action === "create" || !disabled_permalink
        ? valid_permalink + required
        : "";
    },
    setCategories(c_id) {
      if (this.pack.categories[this.pack.categories.length - 1].id) {
        this.pack.categories.push({ id: null, quantity: null });
      }
      let index = this.pack.categories.findIndex((c) => c.id == c_id);
      this.pack.categories[index].quantity =
        this.pack.categories[index].quantity || 1;
    },
    activateOrDesactivatePack() {
      if (this.pack.active) {
        this.$axios
          .delete("packs/" + this.id)
          .then(() => {
            this.pack.active = false;
            this.$root.notify(this.$t("successfully_deleted"), "is-success");
          })
          .catch((e) => this.clientError(e));
      } else {
        this.$axios
          .patch("packs/" + this.id + "/restore")
          .then(() => {
            this.pack.active = true;
            this.$root.notify(this.$t("successfully_restored"), "is-success");
          })
          .catch((e) => this.clientError(e));
      }
    },
    validSeoName(
      event,
      site_index,
      site_id,
      lang_index,
      lang_iso,
      disabled_permalink
    ) {
      if (
        (this.action === "create" || !disabled_permalink) &&
        this.$can("create", "catalog")
      ) {
        let url = `/packs/permalink/${encodeURIComponent(
          event.target.value.split("/").join(" ")
        )}/${site_id}/${lang_iso}/check${
          this.action == "edit" ? "/" + this.id : ""
        }`;
        this.$axios
          .get(url)
          .then((res) => {
            this.$set(
              this.pack.sites[site_index].languages[lang_index],
              "permalink",
              res.data.permalink
            );
          })
          .catch((e) => {
            let permalink =
              e.response && e.response.data && e.response.data.permalink;

            this.$set(
              this.pack.sites[site_index].languages[lang_index],
              "permalink",
              permalink
            );
          });
      }
    },
    save() {
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          this.selectedSite = 0;
          return;
        }

        this.save_loading = true;

        let data = { ...this.pack };
        data.sites = data.sites.reduce((arr, site) => {
          let langs = site.languages.reduce((arr2, lang) => {
            return lang.name && lang.permalink
              ? [
                  ...arr2,
                  {
                    lang_iso: lang.iso,
                    name: lang.name,
                    permalink: lang.permalink,
                    meta_title: lang.meta_title,
                    meta_description: lang.meta_description,
                    directions: lang.directions,
                    description: lang.description,
                    keypoints: lang.keypoints || [],
                  },
                ]
              : arr2;
          }, []);
          return [
            ...arr,
            {
              site_id: site.id,
              discount: site.discount,
              languages: langs,
            },
          ];
        }, []);

        data.categories = data.categories.filter((c) => !!c.id).reduce((arr, c) => {
          if(c.selectProduct) {
            c.prod_id = c.product.id;
            c.brand_id = null;
          }

          delete c.label;
          delete c.permalink;
          delete c.brand;
          delete c.product;
          delete c.selectProduct;

          return [...arr, c];
        }, []);

        delete data.images;

        this.$axios({
          method: this.action == "create" ? "post" : "put",
          url:
            this.action == "create"
              ? "packs"
              : `packs/${this.$route.params.id}`,
          data: data,
        })
          .then((res) => {
            this.$root.notify(
              this.$t(
                this.action == "edit"
                  ? "successfully_updated"
                  : "successfully_created"
              ),
              "is-success"
            );
            this.id = res.data.pack.id;
            this.action = "edit";
            this.activeTab = "images";
          })
          .catch((e) => this.clientError(e))
          .finally(() => (this.save_loading = false));
      });
    },
    getPack() {
      this.loading = true;
      this.$axios
        .get(`packs/${this.$route.params.id || 0}`)
        .then((res) => {
          if (res.data.pack) {
            res.data.pack.categories = res.data.pack.categories.reduce(
              (arr, c) => {
                return [
                  ...arr,
                  { ...c, brand_id: c.brand ? c.brand.id : null, selectProduct: c.product ? true : false },
                ];
              },
              []
            );
            this.pack = res.data.pack;
          } else
            this.pack = {
              active: true,
              category_id: null,
              categories: [{ id: null, quantity: null }],
              sites: res.data.filters.sites,
              noindex: false,
              images: [],
            };

          this.subtitle = this.pack.name;

          this.pack.sites = this.pack.sites.reduce((arr, site) => {
            let langs = site.languages.reduce((arr2, lang) => {
              return [
                ...arr2,
                {
                  description: lang.description,
                  description_short: lang.description_short,
                  h1: lang.h1,
                  iso: lang.iso,
                  label: lang.label,
                  meta_description: lang.meta_description,
                  meta_title: lang.meta_title,
                  keypoints: lang.keypoints || [],
                  directions: lang.directions,
                  name: lang.name,
                  permalink: lang.permalink,
                  site_id: lang.site_id,
                  disabled_permalink: !!lang.permalink,
                },
              ];
            }, []);
            site.languages = langs;
            site.discount = site.discount && site.discount.value;
            return [...arr, site];
          }, []);

          this.categories3rdLevel = res.data.filters.categories.products.reduce(
            (arr, c1) => {
              let sub_children = c1.children.reduce((arr2, c2) => {
                return c2.children ? [...arr2, ...c2.children] : arr2;
              }, []);
              return [...arr, ...sub_children];
            },
            []
          );

          this.sitesIdArr = res.data.filters.sites.reduce((arr, s)=> [...arr, s.id], []);

          // this.categories = res.data.filters.categories.packs.reduce((arr, c1) => {
          //   let sub_children = c1.children.reduce((arr2, c2) => {
          //     return c2.children
          //       ? [...arr2, c2, ...c2.children]
          //       : [...arr2, c2];
          //   }, []);
          //   return [...arr, c1, ...sub_children];
          // }, []);

          let cats = [];
          for (let i = 0; i < res.data.filters.categories.packs.length; i++) {
            const c1 = res.data.filters.categories.packs[i];
            for (let j = 0; j < c1.children.length; j++) {
              const c2 = c1.children[j];
              cats = [...cats, ...c2.children];
            }
          }
          this.categories = cats;

          this.brands = res.data.filters.brands;
        })
        .catch((e) => {
          const code = parseInt(e.response && e.response.status);
          if (code == 404) {
            this.notFound = true;
          }
          this.clientError(e);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
