<template>
  <form
    method="post"
    @submit.prevent="save"
  >
    <ImageUpload
      :allow-multiple="allowMultiple"
      :accepted-file-types="acceptedFileTypes"
      :images="[]"
      @updateFiles="onUpdateFiles"
    />

    <b-field>
      <b-button
        type="is-primary"
        :loading="loading"
        :disabled="loading || action == 'read' || !myFiles.length"
        tag="input"
        native-type="submit"
        :value="$t('save')"
      >
        {{ $t("save") }}
      </b-button>
    </b-field>

    <div class="is-flex is-flex-wrap-wrap is-justify-content-space-between">
      <div
        v-for="(img, i) in images"
        :key="i"
        class="upload-img-container has-border mb-2"
      >
        <p>{{ img.filename }}</p>
        <img
          width="600"
          :src="getImgURL(img)"
        >

        <span
          class="delete-icon"
          @click="confirmDeleting(img, i)"
        >
          <b-icon
            icon="close"
            size="is-small"
          />
        </span>
      </div>
    </div>
  </form>
</template>

<script>
import ImageUpload from "@/components/forms/elements/ImageUpload.vue";

export default {
  components: {
    ImageUpload
  },
  props: {
    acceptedFileTypes: {
      type: String,
      default: "image/png"
    },
    allowMultiple: {
      type: Boolean,
      default: true
    },
    action: {
      type: String,
      required: true
    },
    id: {
      type: [String, Number],
      required: true
    },
    images: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return {
      myFiles: [],
      loading: false
    };
  },

  methods: {
    getImgURL(Img) {
      return `${Img.url}${Img.path}${Img.filename}-w600h600@2x.${Img.version}.jpg`;
    },
    deleteImg(img, index) {
      this.loading = true;
      let url = `packs/images/${this.id}/${img.filename}`;
      this.$axios
        .delete(url)
        .then(() => {
          this.$root.notify(this.$t("successfully_deleted"), "is-success");
          this.images.splice(index, 1);
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.loading = false));
    },
    confirmDeleting(img, index) {
      this.$buefy.dialog.confirm({
        message: `<b>${this.$t("are_you_sure")}</b>`,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        onConfirm: () => this.deleteImg(img, index)
      });
    },
    b64toBlob(dataURI, type) {
      var byteString = atob(dataURI.split(",")[1]);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);

      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: type });
    },
    onUpdateFiles(files) {
      this.myFiles = files;
    },
    save() {
      this.loading = true;
      const formData = new FormData();
      formData.append("ids[0]", this.id);

      for (var i = 0; i < this.myFiles.length; i++) {
        let file = this.myFiles[i];
        formData.append("files[" + i + "]", file);
      }


      this.$axios({
        method: "post",
        url: "packs/images",
        data: formData,
        headers: { "content-type": "multipart/form-data" }
      })
        .then(() => {
          this.$root.notify(this.$t("successfully_updated"), "is-success");
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
